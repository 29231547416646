<template>
  <div class="terms-view">
    <app-header icon="file-alt" title="Incluir Termos de Uso"></app-header>

    <section>
      <form v-if="!isLoading" @submit.prevent="onSubmit()">
        <div class="container-cadastro">
          <div class="title_cadastro">DADOS DO TERMO</div>
          <div class="row">
            <div class="col-9 col-md-9 col-sm-4">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Nome</div>
                  <input
                    class="input-cadastro"
                    type="text"
                    name="name"
                    required
                    v-model="term.name"
                    :errors="errors.name"
                  />
                </div>
              </div>
            </div>

            <div class="col-3 col-md-3 col-sm-1">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Tipo de Termo</div>
                  <b-select
                    id="sel_term"
                    v-model="term.user_type"
                    placeholder="Selecionar"
                    name="user_type"
                    required
                  >
                    <option
                      v-for="userType in userTypes"
                      :key="userType.id"
                      :value="userType.id"
                    >
                      {{ userType.name }}
                    </option>
                  </b-select>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="height: 360px">
            <div class="col-12 col-md-12 col-sm-6">
              <div class="card cadastro">
                <div class="card-body">
                  <div class="card-title-cadastro">Termo de Uso</div>
                  <b-field>
                    <editor
                      api-key="kg7a6eya1chd9spudd90s3udrfu7ncpfblexxmdqcj0n93h8"
                      v-model="term.term"
                      :init="{ height: 300 }"
                    ></editor>
                  </b-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div
              class="col-12 col-md-12 col-sm-6 button-container d-flex justify-content-end"
            >
              <b-button variant="light" @click="goBack()" type="is-light"
                >Voltar</b-button
              >
              <b-button
                class="button-rounded remove-focus btn-success border-0 px-3"
                variant="light"
                @click="onSubmit()"
              >
                Salvar
              </b-button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import TermsService from '@/services/terms.service.js';
import { required } from 'vuelidate/lib/validators';
import Editor from '@tinymce/tinymce-vue';
export default {
  components: {
    editor: Editor,
  },
  data: () => ({
    errors: {},
    isLoading: true,
    title: '',
    term: {},
    userTypes: [],
  }),
  validations: {
    term: {
      name: {
        required,
      },
      user_type: {
        required,
      },
    },
  },
  methods: {
    loadUserTypes() {
      this.userTypes = [
        { id: 1, name: 'Advogado' },
        { id: 2, name: 'Cliente' },
        { id: 3, name: 'Secretária' },
      ];
    },
    loadTerm(id) {
      if (typeof id != 'undefined') {
        this.isLoading = true;
        TermsService.getId(id)
          .then(({ data }) => (this.term = data))
          .finally(() => (this.isLoading = false));
      } else {
        this.isLoading = false;
      }
    },
    onSubmit() {
      this.errors = {};
      !this.id && this.save(this.term);
      this.id && this.update(this.id, this.term);
    },
    save(data) {
      this.isSaving = true;
      TermsService.save(data)
        .then(() => {
          this.$buefy.snackbar.open('Termo cadastrado com sucesso!');
          this.$router.push({ name: 'terms.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Termo.',
            'error'
          );
          this.errors = response.data.message;
        })
        .finally(() => (this.isSaving = false));
    },
    update(id, data) {
      this.isSaving = true;
      TermsService.update(id, data)
        .then(() => {
          this.$buefy.snackbar.open('Termo atualizado com sucesso!');
          this.$router.push({ name: 'terms.index' });
        })
        .catch(({ response }) => {
          const message = response.data.message;
          this.$buefy.snackbar.open(
            typeof message === 'string'
              ? message
              : 'Erro ao tentar atualizar o Termo.',
            'error'
          );
          this.errors = response.data.message;
        })
        .finally(() => (this.isSaving = false));
    },
  },

  mounted() {
    const id = this.$route.params.id;
    this.id = this.$route.params.id;
    this.loadUserTypes();
    this.loadTerm(id);
  },
};
</script>

<style>
#sel_term {
  height: auto;
  font-size: 14px;
}
</style>
